<template>
    <div v-if="type != 'section' && type != 'swiper' && type != 'images'" class="item" :class="'item_' + type">
        <h3 :class="'title_' + type">{{ data.title }}</h3>
        <div :class="'description_' + type" v-html="data.description"></div>
    </div>
</template>
<script>
export default {
    name: 'ItemOnlyTextComponent',
    props: {
        data: Object,
        type: String,
        shrink: Function,
    }
}
</script>
<style scoped>
/** Only Text Styles */


@media (max-width: 1080px) {
    .item_onlyText {
        width: 70%;
        text-align: justify;
        margin: 30px;
        overflow: hidden;
    }

    .image_onlyText {
        display: none;
    }

    .description_onlyText {
        margin-top: 30px;
    }

    .title_onlyText {
        font-size: 1.5em;
    }
}

@media (min-width: 1081px) {
    .item_onlyText {
        width: 45%;
        margin: 30px auto 30px auto;
        overflow: hidden;
        text-align: justify;
    }

    .image_onlyText {
        display: none;
    }

    .description_onlyText {
        margin-top: 30px;
    }

    .title_onlyText {
        font-size: 1.5em;
    }
}
</style>