function getCurrentDateTime() {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Se suma 1 ya que los meses en JavaScript van de 0 a 11
    const day = String(currentDate.getDate()).padStart(2, '0');

    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');

    const timezoneOffsetHours = -Math.floor(currentDate.getTimezoneOffset() / 60); // Convertimos los minutos de desfase en horas
    const timezoneOffsetMinutes = Math.abs(currentDate.getTimezoneOffset() % 60); // Tomamos los minutos restantes

    const timezoneOffsetSign = (timezoneOffsetHours >= 0) ? '+' : '-';

    const timezoneOffsetHoursString = String(Math.abs(timezoneOffsetHours)).padStart(2, '0');
    const timezoneOffsetMinutesString = String(timezoneOffsetMinutes).padStart(2, '0');

    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timezoneOffsetSign}${timezoneOffsetHoursString}:${timezoneOffsetMinutesString}`;

    return formattedDateTime;
}

module.exports = getCurrentDateTime;
