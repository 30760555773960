<template>
    <div :class="'item_' + type">
        <div :class="'image_' + type">
            <LoadImageComponent :src="data.image" :alt="data.title" />
        </div>
        <div :class="'item_' + type + '-right'">
            <h3 :class="'title_' + type">{{ data.title }}</h3>
            <div :class="'description_' + type" v-html="data.description" ></div>
        </div>

    </div>
</template>
<script>
import LoadImageComponent from './LoadImageComponent.vue';

export default {
    name: "ItemListComponent",
    props: {
        data: Object,
        shrink: Function,
        type: String,
    },
    components: { LoadImageComponent }
}
</script>
<style>
/** List Styles */

.item_list {
    padding: 10px 0 10px 0;
    text-align: left;
    /* display: flex;
    align-items: flex-start;
    justify-content: flex-start; */
}

.item_list-right {
    margin-left: 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.image_list {
    position: absolute;
    width: 50px;
    height: 50px;
    box-sizing: content-box;
    border-radius: 50%;
    overflow: hidden;
    background-color: white;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: space-around;
    padding: 10px;
    margin: 20px 10px 10px 10px;
}

.image_list img {
    max-width: 80%;
    margin: auto;
}

.title_list,
.description_list {
    width: 90%;
}
</style>