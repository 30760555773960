<template>
    <div :style="{ backgroundColor: colors[config.background] ?? '#fff', paddingTop: config.top + 'px' ?? '120px' }"
        class="list" :id="menu">
        <h2 :class="'list-title list-title_' + type" :style="{ color: colors[config.color] ?? '#2d2d2d' }">{{ title }}
        </h2>
        <div :class="'container-with-buttons_' + type">
            <div v-show="showLeftButton && type == 'swiper'" class="arrow left-arrow" @click="scrollLeft">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                    <path
                        d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
                </svg>
            </div>
            <div class="container" :class="'container_' + type" ref="container" @scroll="checkScroll">
                <div class="" :class="'items_' + type">
                    <ItemComponent @itemClicked="showPopupFn" v-for="item in list" v-bind:key="item.id" :type="type"
                        :data="item" :colors="colors" :config="config" />
                </div>
            </div>
            <div v-if="type == 'swiper'" class="arrow right-arrow" @click="scrollRight">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                        d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                </svg>
            </div>
        </div>
        <PopupComponent v-if="!hidePopup && config.details" :data="dataPopup" @click="closePopup" :style="popupStyle" />
    </div>
</template>
<script>
import ItemComponent from './ItemComponent.vue';
import PopupComponent from './PopupComponent.vue';

const showPopupFn = function (data) {
    this.hidePopup = false;
    this.popupStyle = {
    }
    this.dataPopup = data;
}
const closePopup = function () {
    this.hidePopup = true;
}

export default {
    name: 'ListComponent',
    props: {
        list: Array,
        title: String,
        type: String,
        colors: Object,
        menu: String,
        config: Object,
    },
    data() {
        return {
            showLeftButton: false,
            dataPopup: {
                description: ""
            },
            hidePopup: true,
            popupStyle: {}
        }
    },
    mounted() {
        this.checkScroll();
    },
    methods: {
        checkScroll() {
            const container = this.$refs.container;
            this.showLeftButton = container.scrollLeft > 0;
        },
        scrollLeft() {
            const container = this.$refs.container;
            container.scrollLeft -= 315;
            this.checkScroll();
        },
        scrollRight() {
            const container = this.$refs.container;
            container.scrollLeft += 315;
            this.checkScroll();
        },
        showPopupFn: showPopupFn,
        closePopup: closePopup,
    },
    components: {
        ItemComponent,
        PopupComponent
    }
}

</script>
<style scoped>
.container {
    padding: 0px 0 30px 0;
}

/**Swipper Style */
.container-with-buttons_swiper {
    display: flex;
}

.container_swiper {
    padding: 20px 0 100px 0;
    margin: 0px;
    display: flex;
    box-sizing: border-box;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
}

.container_swiper::-webkit-scrollbar {
    height: 0px;
    /* Altura de la barra de desplazamiento */
}

.list-title {
    font-size: 2.5em;
    font-style: italic;
}

.items_swiper {
    display: flex;
    box-sizing: content-box;
    padding-left: 35px;
}

.right-arrow {
    width: 0px;
    z-index: 20;
}

.left-arrow {
    width: 0px;
    z-index: 20;
}

.arrow {
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrow>svg {
    position: absolute;
    width: 30px;
    height: 30px;
    color: rgba(255, 255, 255, 0.8);
    filter: drop-shadow(3px 3px 8px rgba(50, 50, 50, 0.7));
    background-color: rgba(200, 200, 200, 0.5);
    border-radius: 50%;
}

.right-arrow>svg {
    margin-right: 45px;
}

.left-arrow>svg {
    margin-left: 45px;
}

/** Only Text */

.container_onlyText {
    padding: 10px;
    margin: 0px;
}

.items_onlyText {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

@media (max-width: 1080px) {
    .onlyText {
        padding-top: 20px;
    }

    .list-title_onlyText {
        font-size: 2em;
        margin: auto;
    }
}


/** List Style */

.list {
    padding-top: 120px;
    background-color: aliceblue;
}

.items_list {
    display: inline;
}

/** Only Images Style */

.items_images {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

/** Section */
.items_section {
    display: block;
}

/** Pricing */
.container_pricing {
    width: 100%;
}

.items_pricing {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
}

/** Images Tape */
.container_imagestape {}

.items_imagestape {
    margin-top: 0px;
    padding-top: 0px;
    width: 100%;
    display: flex;
}

@media (max-width:768px) {
    .items_imagestape {
        flex-wrap: wrap;
    }
}
</style>