<template>
    <img :src="getSrc" :alt="alt">
</template>
<script>

const websiteRef = process.env.VUE_APP_WEBSITE_REF;

export default {
    name: 'LoadImageComponent',
    props: {
        src: String,
        alt: String,
    },
    data() {
        return {
            getSrc: null,
        };
    },
    async created() {
        try {
            const image = await import(`@/assets/${websiteRef}/images/${this.src}`);
            this.getSrc = image.default;
        } catch (error) {
            this.getSrc = this.src;
        }
    },
};
</script>