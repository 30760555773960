<template>
    <div :class="'item_' + type">
        <div class="left">
            <LoadImageComponent :class="'item_'" :src="data.image" alt="data.title" />
        </div>
        <div class="right">
            <h3 :class="'title_' + type">{{ data.title }}</h3>
            <div :class="'description_' + type" v-html="data.description">
            </div>
        </div>
    </div>
</template>
<script scoped>
import LoadImageComponent from './LoadImageComponent.vue';

export default {
    name: 'ItemSectionComponent',
    props: {
        data: Object,
        type: String,
    },
    components: { LoadImageComponent }
}
</script>
<style>
/** Sections Style */
.item_section {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 80px 20px;
    min-height: 400px;
    margin: 0;
}

.left {
    width: 350px;
    display: flex;
    justify-content: space-around;
}

.left img {
    width: 70%;
    margin: auto;
}

.right {
    width: 350px;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
}

.title_section {
    font-size: 2em;
}

.description_section {
    font-size: 1.5em;
    text-align: justify;

}
</style>