<template>
    <div class="popup_overlay">
        <div class="popup_blurred">
            <div class="popup_image-square">
                <LoadImageComponent class="popup_image" :src="data.image" :alt="data.title" />
            </div>
            <div class="popup-description">
                <h3>{{ data.title }}</h3>
                <div class="popup-text" v-html="data.description">
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import LoadImageComponent from './LoadImageComponent.vue';


export default {
    name: "PopupComponent",
    props: {
        data: {
            type: Object,
            default: function () {
                return {
                    description: "Description",
                };
            }
        }
    },
    components: { LoadImageComponent }
}
</script>
<style scoped>
/** Desktop animation */

@media (min-width: 769px) {
    @keyframes aparecer {
        from {
            opacity: 0;
            height: 1px;

        }

        to {
            opacity: 1;
            height: 70%;
        }
    }

}

@keyframes toSmall {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.popup_overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: 100;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.popup_blurred {
    width: 100%;
    height: 70%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(10px);
    animation: aparecer 0.5s ease-in-out;
    transition: all 1s ease-in-out;
    overflow: hidden;
    box-shadow: 2px 2px 15px gray;
}


.popup-description {
    margin: auto;
    height: 450px;
    width: 50%;
    text-align: justify;
    padding: 5% 5% 5% 15%;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    overflow-y: scroll;
}

::-webkit-scrollbar {
    width: 12px; 
    border-radius: 10px; 
}

::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px; 
}



.popup-description h3 {
    text-align: left;
}

.popup_image-square {
    width: 20%;
    margin: auto;
    position: absolute;
    z-index: 110;
    left: 15%;
    background-color: white;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 8px;

    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.5));
    animation: toSmall 1s ease-in-out;
    transition: margin 1s ease-in-out;

}

.popup-text {
    width: 100%;
    height: 100%;
}

/** Mobile Style */

@media (max-width: 768px) {
    @keyframes showMobile {
        from {
            opacity: 0;
            height: 1px;

        }

        to {
            opacity: 1;
            height: 95%;
        }
    }



    .popup_image-square {
        position: absolute;
        top: 5%;
        left: auto;
        width: 60%;
        max-height: 50%;
        animation: toSmall 1s ease-in-out;
        transition: all 1s ease-in-out;
    }

    .popup_blurred {
        margin-top: 100px;
        height: 95%;
        animation: showMobile 0.5s ease-in-out;
    }

    .popup-description {
        margin-top: 100px;
        width: 85%;
        padding: 45% 8% 0% 8%;
        overflow-y: scroll;

    }
}
</style>