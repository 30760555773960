<template>
    <div class="mainbanner">
        <div class="onebanner" v-for="banner in data.data" v-bind:key="banner.id">
            <div class="onebanner-description">
                <h2>{{ banner.title ?? slogan }}</h2>
            </div>
            <div class="onebanner-rotated">
            </div>
            <div class="onebanner-image">
                <LoadImageComponent :src="banner.image" :alt="banner.title" />
            </div>

        </div>

    </div>

</template>
<script>
import LoadImageComponent from './LoadImageComponent.vue';

export default {
    name: "MainBannerComponent",
    props: {
        data: Object,
        slogan: String,
        colors: Object,
    },
    components: {
        LoadImageComponent,
    },

}
</script>
<style scoped>
.mainbanner {
    margin-top: 100px;
    max-width: 100%;
    position: relative;
    overflow: hidden;
}

.onebanner-image {
    width: 100%;
    height: 50%;
    overflow: hidden;
}

.onebanner-rotated {
    position: absolute;
    display: flex;
    margin: 100px 0px auto -400px;
    height: 950px;
    width: 950px;
    background-color: rgba(255, 255, 255, 0.5);

    transition: all 0.1s ease-in-out;
    transform: rotate(45deg);
}

.onebanner-description {

    position: absolute;
    z-index: 20;
    font-size: 30px;
    margin-top: 0px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 30px;
    text-align: left;
    display: flex;

    align-items: flex-end;

}



.onebanner-description h2 {
    width: 50%;
}

@media (max-width: 768px) {
    .mainbanner {
        margin-top: 150px;
    }

    .onebanner-rotated {
        transform: rotate(0deg);
        margin: 00px 0px auto -200px;
        height: 850px;
        width: 850px;
    }

    .onebanner-description {
        margin-top: 30px;
        font-size: 1.2em;
    }

    .onebanner-description h2 {
        width: 80%;

    }

}
</style>