<template>
    <div class="item_imagestape" :style="{ padding: config.padding + `px` }">
        <LoadImageComponent :src="data.image" :alt="data.title" />
    </div>
</template>
<script>
import LoadImageComponent from './LoadImageComponent.vue';

export default {
    name: "ItemImageTapeComponent",
    props: {
        data: Object,
        config: Object,
        colors: Object,
    },
    components: {
        LoadImageComponent,
    }
}
</script>
<style>
.item_imagestape {
    margin: auto;
    box-sizing: border-box;
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.3));
}
</style>