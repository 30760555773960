import axios from 'axios';
const importVars = process.env.VUE_APP_WEBSITE_REF ?? 'website-test';

//console.log(process.env.VUE_APP_WEBSITE_REF);
let sourceData = 'assets/' + importVars + '/data.json';
const dataImported = require(`@/${sourceData}`);

const lists = [
    {
        id: 1,
        title: "Products",
        data: [
            {
                title: "Item 1",
            },
        ],
        class: "layout1",
    },
    {
        id: 0,
        title: "Qualities",
        data: [
            {
                title: "Item 2",
            },
        ],
        class: "layout2",
    },
    {
        id: 2,
        title: "List",
        data: [
            {
                title: "Item 3",
            },
        ],
        class: "layout1",
    },
    {
        id: 3,
        title: "IMAGES",
        data: [
            {
                title: "Item 4",
            },
        ],
        class: "layout1",
    },
    {
        id: 4,
        title: "Sections",
        data: [],
        class: 'none'
    }
];

const data = function () {
    // console.log(dataImported);
    return dataImported;
}
const retrieveFromApi = function () {
    axios.get('https://fakestoreapi.com/products')
        .then(response => {
            this.lists[1].data = response.data.slice(0, 12);
            this.lists[1].type = 'swiper'

            this.lists[0].data = response.data.slice(7, 12);
            this.lists[0].type = 'onlyText'

            this.lists[2].data = response.data.slice(10, 19);
            this.lists[2].type = 'list'

            this.lists[3].data = response.data.slice(11, 20);
            this.lists[3].type = 'images'

            this.lists[4].data = response.data.slice(0, 2);
            this.lists[4].type = 'section'

            console.log(this.lists);
        })
        .catch(error => {
            console.error('Error al obtener usuarios:', error);
        });
}
export default {
    data: data,
    retrieveFromApi: retrieveFromApi,
    lists: lists,
}