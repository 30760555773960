<template>
    <footer id="footer" :style="{ backgroundImage: createBackgroundString }">
        <div class="whatsapp one-social">
            <div class="circle wp-logo-mobile">
                <a class="" aria-label="Contacto por Whatsapp" :href="'https://api.whatsapp.com/send/?phone='
        + company.whatsapp
        + '&text=Hola%2C%20quiero%20conocer%20m%C3%A1s%20acerca%20de%20los%20productos&type=phone_number&app_absent=0'
        ">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                        <path
                            d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                    </svg>
                </a>

            </div>
            <div class="circle wp-logo-pc">
                <a class="" aria-label="Contacto por Whatsapp" :href="'https://web.whatsapp.com/send/?phone='
        + company.whatsapp
        + '&text=Hola%2C%20quiero%20conocer%20m%C3%A1s%20acerca%20de%20los%20productos&type=phone_number&app_absent=0'
        ">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                        <path
                            d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                    </svg>
                </a>

            </div>
        </div>
        <div class="footer-section">
            <div class="footer-logo">
                <LoadImageComponent class="logo" :src="company.logo" :alt="company.name" :style="filterLogo" />
            </div>
        </div>
        <div class="footer-section">
            <h2>Encuéntranos</h2>
            <div class="phones">
                <b>Teléfonos: </b>
                <span v-for=" phone  in  company.phones " v-bind:key="phone">
                    <a :href="'tel:' + phone"> {{ phone }} </a>.
                </span>
            </div>
            <div>
                <h3>{{ company.address }}</h3>
                <h3>{{ company.city }}</h3>
                <h3>{{ company.country }}</h3>
            </div>
        </div>
        <div class="footer-section right-footer">
            <div class="footer-right-up">
                <h2>Contáctanos</h2>
                <h3 class="footer-email"><a :href="'mailto:' + company.email">{{ company.email }}</a> </h3>
            </div>

            <div class="dummy"></div>
            <div class="footer-social">
                <div v-if="social.instagram != null" class="one-social"><a aria-label="Síguenos en Instagram"
                        :href="social.instagram ?? 'https://instagram.com'">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="footer-instagram"
                            viewBox="0 0 16 16">
                            <path
                                d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                        </svg></a>
                </div>
                <div v-if="social.facebook != null" class="one-social">
                    <a aria-label="Síguenos en facebook" :href="social.facebook ?? 'https://facebook.com'">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="footer-facebook"
                            viewBox="0 0 16 16">
                            <path
                                d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                        </svg>
                    </a>
                </div>
                <div v-if="social.youtube != null" class="one-social">
                    <a aria-label="Mira nuestros videos en Youtube" :href="social.youtube ?? 'https://youtube.com'">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="footer-youtube"
                            viewBox="0 0 16 16">
                            <path
                                d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z" />
                        </svg>
                    </a>
                </div>

            </div>


        </div>
        <div class="bottom-copywrite">
            {{ company.domain }} <br><br> <strong class="copy-symbol">&#169;</strong> 2024 <br><br><br>
            <div class="bottom-link">
                Diseñado por <a class="small-link" href="https://webmasters.com.co">Webmasters Colombia</a><br>

            </div>
        </div>
    </footer>
</template>
<script>
import LoadImageComponent from './LoadImageComponent.vue';


const dummy = {
    name: "Name",
    address: "Street 14567",
    phones: ["12343456", "98769876"],
    city: "New York",
    logo: "url-image.png",
    year: 2024,

}

export default {
    name: "FooterComponent",
    props: {
        company: {
            type: Object,
            default: dummy,
        },
        footer: {
            type: Object,
        },
        social: {
            type: Object,
        }
    },
    computed: {
        createBackgroundString() {
            return `linear-gradient(${this.footer.footerDeg}deg, ${this.footer.from} 0%, ${this.footer.to ?? '#282828'} 100%)`;
        },
        filterLogo() {
            console.log(this.footer);
            return this.footer.whiteLogo ? { filter: 'contrast(0) brightness(200%)' } : { filter: 'drop-shadow(2px 2px 5px rgba(255, 255, 255, 0.4))' };
        }
    },
    components: { LoadImageComponent }
}
</script>
<style scoped>
.whatsapp {
    width: 1000px;
    height: 1px;
    position: fixed;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    bottom: 50px;
    margin-left: 900px;
    text-align: right;
}

.whatsapp>div>a>svg {
    color: #25D366;
    width: 40px;
}

.whatsapp .circle {
    width: 80px;
    height: 80px;
    margin-left: 0px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;

    justify-content: center;
    align-items: center;
}

footer {
    /* background-color: #282828;
    background-image: linear-gradient(to left, #282828 0%, #3b5998 100%); */
    color: #fff;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.footer-section {
    width: 30%;
    margin: 10px;
}

.footer-logo {
    max-width: 300px;
    margin: auto;
}

.footer-logo img {
    max-width: 100%;
    margin: auto;


}

.footer-section p,
.footer-section a,
.footer-section h3,
.footer-section h2 {
    text-align: left;
}



@media (min-width:769px) {
    .wp-logo-mobile {
        display: none;
    }

    .wp-logo-pc {
        display: flex;
    }
}

.right-footer {
    display: flex;
    flex-wrap: wrap;

}


.footer-right-up {
    width: 100%;
}

.phones {
    text-align: left;
    width: 100%;
}

.phones a {
    text-decoration: none;
    color: white;
}

.footer-email a {
    color: rgba(255, 255, 255, 0.9);
    text-decoration: none;
    font-size: 0.7em;
}

.bottom-copywrite {
    width: 100%;
    padding-top: 30px;
}

.copy-symbol {
    font-size: 1.2em;
}

/**social icons */
.footer-social {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    align-content: center;
    text-align: center;
}

.one-social {
    margin: auto 10px;
}

.one-social svg {
    width: 30px;
}

.footer-social a {
    text-decoration: none;
    color: white;
}

.footer-instagram:hover {
    color: #b5008e;

}

.footer-facebook:hover {
    color: #3B5998;
    border-radius: 50%;
    box-shadow: 0px 0px 0px rgba(255, 255, 255, 1);

}

.footer-youtube:hover {
    color: rgb(255, 0, 0);
}

.bottom-link {
    position: absolute;
    margin-top: -10px;
    color: rgba(250, 250, 250, 0.5);
    font-size: 0.7em;
}

.small-link {
    text-decoration-line: none;
    color: rgba(250, 250, 250, 0.8);
}

/** Responsive Styles */
@media (max-width: 768px) {
    footer {
        background-image: linear-gradient(to top, #282828 0%, #3b5998 100%);
    }

    .footer-section {
        flex: 100%;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .footer-section h2,
    .footer-section h3,
    .footer-secion p {
        width: 100%;
        text-align: center;
    }

    .footer-section>div {

        text-align: center;
        margin: auto;
    }

    .footer-social {
        margin: auto;
    }

    .phones {
        text-align: center;
        width: 100%;
    }

    .whatsapp {
        width: 90%;
    }

    .wp-logo-mobile {
        display: flex;
    }

    .wp-logo-pc {
        display: none;
    }
}
</style>