<template>
  <NavbarComponent :company="company" :colors="colors" />
  <MainBannerComponent v-if="mainbanner != {}" :data="mainbanner" :slogan="company.slogan" :colors="colors" />
  <ListComponent v-for="list in lists" v-bind:key="list.id" :list="list.data" :class="list.type ?? 'layout1'"
    :title="list.title" :type="list.type" :colors="colors" :menu="list.menu" :config="list.config ?? {}" />
  <hr>
  <FooterComponent :company="company" :footer="footer" :colors="colors" :social="social" />
</template>

<script>
import FooterComponent from './components/FooterComponent.vue';
import ListComponent from './components/ListComponent.vue';
import MainBannerComponent from './components/MainBannerComponent.vue';
import NavbarComponent from './components/NavbarComponent.vue';
import DataWebsite from './services/DataWebsite';

export default {
  name: 'App',
  components: {
    ListComponent,
    FooterComponent,
    NavbarComponent,
    MainBannerComponent,
  },
  data() {
    return {
      lists: [],
      company: {},
      colors: {},
      footer: {},
      social: {},
      mainbanner: {},
    }
  },
  mounted() {
    this.lists = DataWebsite.data().lists;
    this.company = DataWebsite.data().company;
    this.colors = {
      primary: this.company.colors.mainColor,
      secondary: this.company.colors.secondColor,
      third: this.company.colors.thirdColor,
      contrast: this.company.colors.contrastColor,
      clear: this.company.colors.clearColor,
      dark: this.company.colors.darkColor ?? '#2D2D2D',
    }
    this.footer = {
      footerDeg: this.company.footer.footerDeg,
      from: this.company.colors[this.company.footer.from],
      to: this.company.colors[this.company.footer.to],
      whiteLogo: this.company.footer.whiteLogo ?? false,
    }
    this.social = this.company.social;
    this.mainbanner = DataWebsite.data().mainbanner;
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
  margin: 0px auto 0px auto;
  max-width: 1080px;
  background-color: rgb(250, 250, 250);
}

html {
  scroll-behavior: smooth;
}

img {
  max-width: 100%;
}
</style>
