<template>
    <header>
        <div class="container_header">
            <div class="logo">
                <a href="/">

                    <LoadImageComponent :src="company.logo ?? 'logo.png'" :alt="company.name ?? 'Company'" />
                </a>
            </div>
            <nav>
                <ul>
                    <li class="menu-item" v-for="menu in company.menu" :key="menu.name">
                        <a :href="menu.link">{{ menu.name }}</a>
                    </li>
                </ul>
            </nav>
        </div>
    </header>
</template>
<script>
import LoadImageComponent from './LoadImageComponent.vue';

export default {
    name: "NavbarComponent",
    props: {
        company: Object,
    },
    components: { LoadImageComponent }
}
</script>
<style>
header {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
}

.container_header {
    max-width: 960px;
    margin: 0 auto;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    flex-shrink: 0;
}

.logo img {
    max-height: 80px;
}

nav {
    text-align: right;
}

nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.menu-item {
    display: inline-block;
    margin-left: 20px;
}

nav ul li a {
    text-decoration: none;
    color: #333;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
}

nav ul li a:hover {
    color: #000;
}

@media (max-width: 768px) {
    .container_header {
        flex-direction: column;
        align-items: center;
    }

    nav {
        text-align: left;
    }

    nav ul {
        padding: 10px;
    }

    .menu-item {
        margin: 0 5px;
    }

    .menu-item a {
        font-weight: 600;
    }
}
</style>