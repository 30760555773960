<template>
    <div :class="'item_' + type">
        <div :class="'image_' + type">
            <LoadImageComponent :src="data.image" :alt="data.title" />
        </div>
        <div :class="'title_container_' + type">
            <h3 :class="'title_' + type">{{ shrink(data.title, type) }}</h3>
            <div v-if="data.price != null" :class="'price_' + type">$ {{ data.price }}</div>
        </div>


        <div :class="'description_' + type">{{ shrink(data.description, type) }}</div>
    </div>
</template>
<script>
import LoadImageComponent from './LoadImageComponent.vue';

export default {
    name: "ItemSwiperComponent",
    props: {
        type: String,
        data: Object,
        shrink: Function,
        setSrc: String,
    },
    components: { LoadImageComponent }
}
</script>
<style scoped>
/**Swiper Styles */
.item_swiper {
    width: 280px;
    height: 450px;
    background-color: rgb(255, 255, 255);
    border-radius: 0px;
    padding: 0px;
    margin: 0px 30px 0px 5px;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
}

.title_container_swiper {
    width: 100%;
    display: flex;
    justify-content: flex-start
}

.title_swiper {
    margin: auto;
    width: 70%;
    text-align: left;
    box-sizing: border-box;
    padding-left: 10px;
}

.price_swiper {
    margin: auto;
}

.image_swiper {
    width: 100%;
    height: 350px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
    overflow: hidden;
    background-color: rgb(250, 250, 250);
    border-bottom: 1px solid rgb(200, 200, 200);
}

.image_swiper>img {
    max-width: 120%;
    max-height: 105%;
    margin: auto;

    filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.5));
}

.description_swiper {
    display: none;
    margin: 20px;
    text-align: left;
}
</style>