<template>
    <div class="plan" :class="data.highlight ? 'destacado' : ''">
        <h2>{{ data.title }}</h2>
        <div class="pricing_description">{{ data.description }}</div>
        <ul>
            <li v-for="feature in data.features" :key="feature.name">{{ feature.name }}: {{ feature.value }}</li>

        </ul>
        <p class="precio">{{ data.price ? '$' + data.price + '/1er año' : 'Contáctanos' }}</p>
        <a :href="data.paymentLink ?? '#footer'">{{ (data.paymentLink ) ? 'Comprar' : 'Contacto' }}</a>
    </div>
</template>
<script>

export default {
    name: "ItemPricingComponent",
    props: {
        data: Object,
        // shrink: Function,
        type: String,
    }
}

</script>
<style scoped>
/* Estilos para la sección de planes */


.plan {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    width: 300px;
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: space-between;

}

.plan h2 {
    font-size: 18px;
    margin-top: 0;
}

.pricing_description {
    text-align: justify;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid rgb(200, 200, 200);
    margin: 20px;
    border-radius: 8px;

}

.plan ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.plan li {
    margin-bottom: 10px;
}

.plan .precio {
    font-size: 24px;
    font-weight: bold;
    margin: 20px 0;
}

.plan a {
    width: 80%;
    background-color: #000;
    color: #fff;
    display: block;
    margin: auto;
    padding: 10px 20px;
    text-decoration: none;
}

/* Estilos para el plan destacado */
.plan.destacado {
    background-color: #f7f7f7;
    border-color: #000;
}

.plan.destacado h2 {
    color: #000;
}

.plan.destacado .precio {
    color: #000;
}

.plan.destacado a {
    background-color: #000;
}
</style>