const nowDate = require('./getCurrentDateTime');

const socialLinks = function (company) {
    let social = [];
    for (const key in company.social) {
        if (company.social[key] != null) {
            social.push(company.social[key]);
        }
    }
    return social;
}


function googleSchemeGenerator(data) {
    return {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": "LocalBusiness",
                "name": data.company.name,
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": data.company.address,
                    "addressCountry": "CO",
                    "addressLocality": data.company.city,
                    "addressRegion": data.company.city,
                    "postalCode": "110831"
                },
                "description": data.company.description,
                "url": 'https://' + data.company.domain + '/',
                "telephone": data.company.phones[0],
                "image": {
                    "@id": `https://${data.company.domain}/#/schema/logo/image/`
                }
            },
            {
                "@type": "WebPage",
                "@id": `https://${data.company.domain}/`,
                "url": `https://${data.company.domain}/`,
                "name": data.company.name,
                "isPartOf": {
                    "@id": `https://${data.company.domain}/#website`
                },
                "about": {
                    "@id": `https://${data.company.domain}/#organization`
                },
                "datePublished": "2024-02-12T15:30:38+00:00",
                "dateModified": nowDate(),
                "description": data.company.description,
                "inLanguage": "es",
                "potentialAction": [
                    {
                        "@type": "ReadAction",
                        "target": [
                            `https://${data.company.domain}/`
                        ]
                    }
                ]
            },
            {
                "@type": "BreadcrumbList",
                "@id": "https://${data.company.domain}/#breadcrumb",
                "itemListElement": [
                    {
                        "@type": "ListItem",
                        "position": 1,
                        "name": "Portada"
                    }
                ]
            },
            {
                "@type": "WebSite",
                "@id": `https://${data.company.domain}/#website`,
                "url": `https://${data.company.domain}/`,
                "name": data.company.name,
                "description": data.company.description,
                "publisher": {
                    "@id": `https://${data.company.domain}/#organization`
                },
                "inLanguage": "es"
            },
            {
                "@type": "Organization",
                "@id": `https://${data.company.domain}/#organization`,
                "name": data.company.name,
                "url": `https://${data.company.domain}/`,
                "logo": {
                    "@type": "ImageObject",
                    "inLanguage": "es",
                    "@id": `https://${data.company.domain}/#/schema/logo/image/`,
                    "url": `https://${data.company.domain}/${data.company.logo}`,
                    "contentUrl": `https://${data.company.domain}/${data.company.logo}`,
                    "width": 400,
                    "height": 400,
                    "caption": data.company.name
                },
                "image": {
                    "@id": `https://${data.company.domain}/#/schema/logo/image/`
                },
                "sameAs": [
                    ...socialLinks(data.company)
                ]
            }
        ]
    }
}
module.exports = googleSchemeGenerator;