<template>
  <div @click="itemClicked" v-if="type != 'section' && type != 'swiper'" class="item" :class="'item_' + type">
    <div :class="'title_' + type">
      <div class="title-tape" :style="tapeStyle">
        <p>
          {{ data.title }}
        </p>
      </div>

    </div>
    <div :class="'image_' + type" v-on:mouseover="titleAppear()" v-on:mouseout="titleDisappear()">
      <LoadImageComponent :src="data.image" :alt="data.title" />
    </div>

  </div>
</template>
<script>
import LoadImageComponent from './LoadImageComponent.vue';



export default {
  name: 'ItemImagesComponent',
  props: {
    data: Object,
    type: String,
    colors: Object,
    config: Object,
  },
  data() {
    return {
      mainColor: "purple",
    }
  },
  methods: {
    itemClicked() {
      this.$emit('itemClicked', this.data)
    },

  },
  computed: {
    tapeStyle() {
      return {
        borderTop: '1px solid ' + this.colors.primary ?? 'black',
        borderBottom: '1px solid ' + this.colors.primary ?? 'black',
        color: this.colors[this.config.color] ?? 'black',
        backdropFilter: this.config.blur ? `blur(3px)` : '',
      }
    }
  },
  components: { LoadImageComponent }
}
</script>
<style scoped>
.item_images {
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 30px auto 30px auto;
  overflow: hidden;
}

.image_images {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
}

.image_images>img {
  max-width: 120%;
  max-height: 120%;
  margin: auto;
}

.title_images {
  position: absolute;
  width: 300px;
  height: 300px;
  margin: 0px auto auto auto;
  border-radius: 50%;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.2em;
}

.title_images .title-tape {
  width: 100%;
  height: 130px;
  background-color: rgba(255, 255, 255, 0.7);
  color: rgb(50, 50, 50);
  display: flex;
  justify-content: center;
  align-items: center;
}

.title_images p {
  margin: auto;
}
</style>